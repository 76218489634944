<template>
  <b-container>
    <b-row>
      <b-col sm="auto" md="10" lg="8" xl="6">
        <h1>Närvaro</h1>
        <b-form inline>
          <b-form-select
            class="mb-2 mr-sm-2 mb-sm-0"
            :options="[{ text: 'Välj termin...', value: '' }, 'ht-2021', 'vt-2022', 'ht-2022', 'vt-2023', 'ht-2023', 'vt-2024', 'ht-2024']"
            v-model="selectedPeriod"
            ></b-form-select>
          <b-form-select
            v-if="selectedPeriod"
            class="mb-2 mr-sm-2 mb-sm-0"
            :options="[{ text: 'Välj grupp...', value: '' }, ...groups]"
            v-model="selectedGroupId"
            ></b-form-select>
          <b-form-select
            v-if="selectedGroupId"
            class="mb-2 mr-sm-2 mb-sm-0"
            :options="[{ text: 'Välj pass...', value: '' }, ...workouts]"
            v-model="selectedWorkout"
            ></b-form-select>
          <b-card v-if="selectedWorkout"
                  :title="new Date(selectedWorkout.date).toLocaleDateString('sv-se', { weekday:'long', year:'numeric', month:'long', day:'numeric'})"
                  :sub-title="'Kl ' + selectedWorkout.startTime + ', ' + selectedWorkout.duration + ' min'"
                  class="mt-3">
            <template #title>
              {{selectedWorkout.date}} {{selectedWorkout.startTime }}
            </template>
            <h3>Instruktörer</h3>
            <ul>
              <li v-for="instructor in instructors" :key="instructor.id">{{members.get(instructor).name}}</li>
            </ul>
            <h3>Elever</h3>
            <ul>
              <li v-for="attendee in attendees" :key="attendee.id">{{members.get(attendee).name}}</li>
            </ul>
          </b-card>
        </b-form>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
  import { db } from '@/firebaseConfig.js'

  export default {
    data() {
      return {
        selectedPeriod: '',
        selectedGroupId: '',
        selectedWorkout: '',
        workoutAttendees: [],
        loading: false,
        groupsByPeriod: new Map(),
        workoutsByGroup: new Map(),
        membersByGroup: new Map()
      }
    },
    computed: {
      groups() {
        const period = this.selectedPeriod;
        if (period && !this.loading) {
          const cachedGroups = this.groupsByPeriod.get(period)
          if (cachedGroups) {
            return cachedGroups;
          } else {
            const groups = this.fetchGroups(period);
            this.groupsByPeriod.set(period, groups);
            return groups;
          }
        }
        return [];
      },
      workouts() {
        const groupId = this.selectedGroupId;
        if (groupId && !this.loading) {
          const cachedWorkouts = this.workoutsByGroup.get(groupId)
          if (cachedWorkouts) {
            return cachedWorkouts;
          } else {
            const workouts = this.fetchWorkouts(groupId);
            this.workoutsByGroup.set(groupId, workouts);
            return workouts;
          }
        }
        return [];
      },
      members() {
        const groupId = this.selectedGroupId;
        if (groupId && !this.loading) {
          const cachedMembers = this.membersByGroup.get(groupId)
          if (cachedMembers) {
            return cachedMembers;
          } else {
            const members = this.fetchMembers(groupId);
            this.membersByGroup.set(groupId, members);
            return members;
          }
        }
        return new Map();
      },
      instructors() {
        if (this.selectedWorkout) {
          return this.selectedWorkout.instructors;
        } else {
          return []
        }
      },
      attendees() {
        if (this.selectedWorkout) {
          return this.selectedWorkout.attendees;
        } else {
          return []
        }
      }
    },
    watch: {
      selectedPeriod: function() {
        this.selectedGroupId = '';
      },
      selectedGroupId: function() {
        this.selectedWorkout = '';
      }
    },
    methods: {
      async fetchGroups(period) {
        this.loading = true;
        const array = [];
        const snapshot = await db
              .collection('groups')
              .where('period', '==', period)
              .orderBy('sort')
              .get();
        snapshot.forEach((doc) => {
          const choice = {
            text: doc.data().name,
            value: doc.id
          };
          array.push(choice);
        });
        this.groupsByPeriod.set(period, array);
        this.loading = false;
        return array;
      },
      async fetchMembers(groupId) {
        this.loading = true;
        const map = new Map();
        const snapshot = await db
              .collection('groupMembers')
              .where('groupId', '==', groupId)
              .get();
        snapshot.forEach((doc) => {
          map.set(doc.id, doc.data());
        });
        this.membersByGroup.set(groupId, map);
        this.loading = false;
        return map;
      },
      async fetchWorkouts(groupId) {
        console.log(`Fetching workouts for ${groupId}`);
        this.loading = true;
        const array = [];
        const snapshot = await db
              .collection('workouts')
              .where('groupId', '==', groupId)
              .orderBy('date')
              .get();
        snapshot.forEach((doc) => {
          array.push({ text: doc.data().date, value: { id: doc.id, ...doc.data() } });
        });
        this.workoutsByGroup.set(groupId, array);
        this.loading = false;
        return array;
      }
    }
  }

</script>
